export let scriptCookies = {
  'es-CO': [
    {
      type: 'text/javascript',
      src:
        'https://cdn-ukwest.onetrust.com/consent/b84b097d-a1d9-44a7-8d55-809f8be7c351/OtAutoBlock.js',
    },
    {
      type: 'text/javascript',
      src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
      'data-document-language': 'true',
      charset: 'UTF-8',
      'data-domain-script': 'b84b097d-a1d9-44a7-8d55-809f8be7c351',
    },
  ],
  'es-EC': [
    {
      type: 'text/javascript',
      src:
        'https://cdn-ukwest.onetrust.com/consent/70295d8c-0ae1-45b9-871c-e16614945b4b/OtAutoBlock.js',
    },
    {
      type: 'text/javascript',
      src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
      'data-document-language': 'true',
      charset: 'UTF-8',
      'data-domain-script': '70295d8c-0ae1-45b9-871c-e16614945b4b',
    },
  ],
  'es-PE': [
    {
      type: 'text/javascript',
      src:
        'https://cdn-ukwest.onetrust.com/consent/62493824-fc59-4d02-81aa-a916d1a14b45/OtAutoBlock.js',
    },
    {
      type: 'text/javascript',
      src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
      'data-document-language': 'true',
      charset: 'UTF-8',
      'data-domain-script': '62493824-fc59-4d02-81aa-a916d1a14b45',
    },
  ],
  'es-CL': [
    {
      type: 'text/javascript',
      src:
        'https://cdn-ukwest.onetrust.com/consent/e8517f36-4578-4491-9d4d-9c81d5569352/OtAutoBlock.js',
    },
    {
      type: 'text/javascript',
      src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
      'data-document-language': 'true',
      charset: 'UTF-8',
      'data-domain-script': 'e8517f36-4578-4491-9d4d-9c81d5569352',
    },
  ],
  'es-AR': [
    {
      type: 'text/javascript',
      src:
        'https://cdn-ukwest.onetrust.com/consent/c8b8cb57-445a-4fb5-b658-f72b9127f034/OtAutoBlock.js',
    },
    {
      type: 'text/javascript',
      src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
      'data-document-language': 'true',
      charset: 'UTF-8',
      'data-domain-script': 'c8b8cb57-445a-4fb5-b658-f72b9127f034',
    },
  ],
  'es-BO': [
    {
      type: 'text/javascript',
      src:
        'https://cdn-ukwest.onetrust.com/consent/9ace4d0e-b5f5-4687-becd-b50399aa654e/OtAutoBlock.js',
    },
    {
      type: 'text/javascript',
      src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
      'data-document-language': 'true',
      charset: 'UTF-8',
      'data-domain-script': '9ace4d0e-b5f5-4687-becd-b50399aa654e',
    },
  ],
  'es-PR': [
    {
      type: 'text/javascript',
      src:
        'https://cdn-ukwest.onetrust.com/consent/f0e56d16-6e5a-4c7a-bd5c-5132de66593d/OtAutoBlock.js',
    },
    {
      type: 'text/javascript',
      src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
      'data-document-language': 'true',
      charset: 'UTF-8',
      'data-domain-script': 'f0e56d16-6e5a-4c7a-bd5c-5132de66593d',
    },
  ],
  'es-PY': [
    {
      type: 'text/javascript',
      src:
        'https://cdn-ukwest.onetrust.com/consent/d3236dc2-d5d4-4f8e-b6fa-c36cb8b6005a/OtAutoBlock.js',
    },
    {
      type: 'text/javascript',
      src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
      'data-document-language': 'true',
      charset: 'UTF-8',
      'data-domain-script': 'd3236dc2-d5d4-4f8e-b6fa-c36cb8b6005a',
    },
  ],
  'es-UY': [
    {
      type: 'text/javascript',
      src:
        'https://cdn-ukwest.onetrust.com/consent/5b20078e-aeb8-44f5-a32e-6e5fe38f5b23/OtAutoBlock.js',
    },
    {
      type: 'text/javascript',
      src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
      'data-document-language': 'true',
      charset: 'UTF-8',
      'data-domain-script': '5b20078e-aeb8-44f5-a32e-6e5fe38f5b23',
    },
  ],
  es: [
    {
      type: 'text/javascript',
      src:
        'https://cdn-ukwest.onetrust.com/consent/4bf52166-d02f-4724-b5c7-cac902599974/OtAutoBlock.js',
    },
    {
      type: 'text/javascript',
      src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
      'data-document-language': 'true',
      charset: 'UTF-8',
      'data-domain-script': '4bf52166-d02f-4724-b5c7-cac902599974',
    },
  ],
}
