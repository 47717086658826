import React, { useEffect } from 'react'

// import imgGif from '../../../assets/images/GIF2.gif'
import nosotras from '../../../assets/images/nosotras.png'
import imgLogoNol from '../../../assets/images/logo.svg'
import iconCo from '../../../assets/images/icon-co.png'
import iconAr from '../../../assets/images/icon-ar.png'
import iconBo from '../../../assets/images/icon-bo.png'
import iconCl from '../../../assets/images/icon-cl.png'
import iconEc from '../../../assets/images/icon-ec.png'
import iconPy from '../../../assets/images/icon-py.png'
import iconPe from '../../../assets/images/icon-pe.png'
import iconPr from '../../../assets/images/icon-pr.png'
import iconDo from '../../../assets/images/icon-do.png'
import iconUy from '../../../assets/images/icon-uy.png'

import './landing-index.scss'

const LandingIndex = () => {
  const countries = {
    'es-CO': {
      label: 'Colombia',
      icon: iconCo,
      link: 'https://www.nosotrasonline.com.co',
    },
    'es-AR': {
      label: 'Argentina',
      icon: iconAr,
      link: 'https://www.nosotrasonline.com.ar',
    },
    'es-BO': {
      label: 'Bolivia',
      icon: iconBo,
      link: 'https://www.nosotrasonline.com.bo',
    },
    'es-CL': {
      label: 'Chile',
      icon: iconCl,
      link: 'https://www.nosotrasonline.cl',
    },
    'es-EC': {
      label: 'Ecuador',
      icon: iconEc,
      link: 'https://www.nosotrasonline.com.ec',
    },
    'es-PY': {
      label: 'Paraguay',
      icon: iconPy,
      link: 'https://www.nosotrasonline.com.py',
    },
    'es-PE': {
      label: 'Perú',
      icon: iconPe,
      link: 'https://www.nosotrasonline.com.pe',
    },
    'es-PR': {
      label: 'Puerto Rico',
      icon: iconPr,
      link: 'https://www.nosotrasonline.com.pr',
    },
    'es-DO': {
      label: 'República Dominicana',
      icon: iconDo,
      link: 'https://www.nosotrasonline.com.do',
    },
    'es-UY': {
      label: 'Uruguay',
      icon: iconUy,
      link: 'https://www.nosotrasonline.com.uy',
    },
  }

  useEffect(() => {
    let locale = localStorage.getItem("locale_country");
    if (locale) {
      window.location.href = countries[locale].link;
    }
  });

  return (
    <div className="App-index">
      <div className="App-container">
        <section className="section-img">
          <img src={nosotras} alt="NosotrasOnline" />
        </section>
        <section className="section-content">
          <div className="container-title">
            <h1 className="title">
              Bienvenida a <img src={imgLogoNol} alt="Logo: NosotrasOnline" />
            </h1>
            <p>Para comenzar selecciona tu país:</p>
          </div>
          <div className="card-container">
            <div className="container-countries">
              {Object.keys(countries).map(key => {
                return (
                  <a
                    className={`f-landing-paises-${key}`}
                    key={key}
                    href={countries[key].link}
                    onClick={(e) => {
                      localStorage.setItem("locale_country", key);
                    }}
                  >
                    <img
                      src={countries[key].icon}
                      alt={'Icono: ' + countries[key].label}
                    />{' '}
                    {countries[key].label}
                    <span className="f-icon icon-down f-icon-drop"></span>
                  </a>
                )
              })}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default LandingIndex
