import React from 'react'
import LandingIndex from '../components/organisms/landing-index/landing-index'
import Helmet from 'react-helmet'
import {scriptCookies} from '../shared/utils/scriptCookies'
function IndexPage() {
  return (
    <div>
      <Helmet script={scriptCookies['es']} />
      <LandingIndex />
    </div>
  )
}

export default IndexPage
